<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="openDialog">
                mdi-file-export
            </v-icon>
        </template>

        <span>Carga de documentos</span>

        <v-dialog v-model="dialog"  fullscreen persistent>
            <v-card>
                <div>
                    <v-card-title class="text-h6 grey lighten-2">
                        <span class="headline">Carga de documentos</span>
                        <v-btn plain icon @click="close" class="ml-auto mr-0">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="pt-2 pl-2 pr-2">
                        <v-row>
                            <v-col lg="6" md="6" sm="12">
                                <ItemViewDocument v-if="itemCurrent.identity" :name="itemCurrent.identity_name" :url="itemCurrent.identity"/>
                                <v-file-input
                                    :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                    show-size
                                    :loading="loading"
                                    small-chips
                                    label="Identificación oficial del solicitante"
                                    truncate-length="50"
                                    v-model="identity"
                                    >
                                </v-file-input>
                            </v-col>
                            <v-col lg="6" md="6" sm="12">
                                <ItemViewDocument v-if="itemCurrent.functionality_alert" :name="itemCurrent.functionality_alert_name" :url="itemCurrent.functionality_alert"/>
                                <v-file-input
                                    :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                    show-size
                                    :loading="loading"
                                    small-chips
                                    label="Aviso de Funcionamiento"
                                    truncate-length="50"
                                    v-model="functionality_alert"
                                    >
                                </v-file-input>
                            </v-col>
                            <v-col lg="6" md="6" sm="12">
                                <ItemViewDocument v-if="itemCurrent.cedula" :name="itemCurrent.cedula_name" :url="itemCurrent.cedula"/>
                                <v-file-input
                                    :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                    show-size
                                    :loading="loading"
                                    small-chips
                                    label="Título y cédula profesional del solicitante en formato PDF"
                                    truncate-length="50"
                                    v-model="cedula"
                                    >
                                </v-file-input>
                            </v-col>
                            <v-col lg="6" md="6" sm="12">
                                <ItemViewDocument v-if="itemCurrent.specialty_cedula" :name="itemCurrent.specialty_cedula_name" :url="itemCurrent.specialty_cedula"/>
                                <v-file-input
                                    :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                    show-size
                                    :loading="loading"
                                    small-chips
                                    label="Cédula de especialidad médica del solicitante en formato PDF"
                                    truncate-length="50"
                                    v-model="specialty_cedula"
                                    >
                                </v-file-input>
                            </v-col>
                            <v-col lg="6" md="6" sm="12">
                                <ItemViewDocument v-if="itemCurrent.specialty_conacem" :name="itemCurrent.specialty_conacem_name" :url="itemCurrent.specialty_conacem"/>
                                <v-file-input
                                    :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                    show-size
                                    :loading="loading"
                                    small-chips
                                    label="Constancia de la certificación de su especialidad, emitida por el Comité Normativo Nacional de Consejos de Especialidades Médicas (CONACEM)."
                                    truncate-length="50"
                                    v-model="specialty_conacem"
                                    >
                                </v-file-input>
                            </v-col>
                            <v-col lg="6" md="6" sm="12">
                                <ItemViewDocument v-if="itemCurrent.photo" :name="itemCurrent.photo_name" :url="itemCurrent.photo"/>
                                <v-file-input
                                    :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                    show-size
                                    :loading="loading"
                                    small-chips
                                    label="Fotografía a color del médico especialista"
                                    truncate-length="50"
                                    v-model="photo"
                                    >
                                </v-file-input>
                            </v-col>
                            <v-col lg="6" md="6" sm="12">
                                <ItemViewDocument v-if="itemCurrent.international_specialty" :name="itemCurrent.international_specialty_name" :url="itemCurrent.international_specialty"/>
                                <v-file-input
                                    :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                    show-size
                                    :loading="loading"
                                    small-chips
                                    label="Constancia(s) de su acreditación internacional de su especialidad en un solo formato PDF"
                                    truncate-length="50"
                                    v-model="international_specialty"
                                    >
                                </v-file-input>
                            </v-col>
                            <v-col lg="6" md="6" sm="12">
                                <ItemViewDocument v-if="itemCurrent.coepris_repssabi" :name="itemCurrent.coepris_repssabi_name" :url="itemCurrent.coepris_repssabi"/>
                                <v-file-input
                                    :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                    show-size
                                    :loading="loading"
                                    small-chips
                                    label="Constancia del Registro Estatal de Establecimientos Prestadores de Servicios de Salud y Bienestar (REPSSABI) de la COEPRIS"
                                    truncate-length="50"
                                    v-model="coepris_repssabi"
                                    >
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="d-flex">
                        <v-btn class="mr-2 ml-auto"
                            color="info" 
                            @click="save" 
                            :loading="saveChanges" :disabled="loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX') || !valid"
                            >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>            
    </v-tooltip>
</template>
<script>
import doctorServices from "@/providers/DoctorsService";
import ItemViewDocument from "@/components/form-documents-doctor/ItemViewDocument";

export default {
    name: "FormDocumentsDoctor",
    props: ["item"],
    components: {
        ItemViewDocument
    },
    data(){
        return {
            dialog: false,
            loading: false,
            saveChanges: false,
            identity: null,
            itemCurrent: {},
            functionality_alert: null,
            cedula: null,
            specialty_cedula: null,
            specialty_conacem: null,
            photo: null,
            international_specialty: null,
            coepris_repssabi: null,
            user: {},
        }
    },
    computed: {
        valid(){
            return this.identity || this.functionality_alert || this.cedula || this.specialty_cedula || this.specialty_conacem || this.photo || this.international_specialty || this.coepris_repssabi;
        }
    },
    methods: {
        loadData(){
            this.loading = true;
            doctorServices.getRecord(this.item.id).then(resp=>{
                this.itemCurrent = resp.value;
            }).catch(()=>{
                this.snackText = "Ha ocurrido un error!";
                this.snackBar = true;
            }).finally(()=>{
                this.loading = false;
            })
        },
        openDialog(){
            this.dialog = true;
            this.resetForm();
            this.loadData();
        },
        close(){
            this.dialog = false;
            this.resetForm();
        },
        resetForm(){
            this.functionality_alert = null;
            this.cedula = null;
            this.specialty_cedula = null;
            this.specialty_conacem = null;
            this.photo = null;
            this.international_specialty = null;
            this.coepris_repssabi = null;
            this.identity = null;
        },
        save(){
            this.saveChanges = true;
            doctorServices.uploadDocumentForm(this.item.id, this.identity, this.functionality_alert, this.cedula, 
                                              this.specialty_cedula, this.specialty_conacem, this.photo, 
                                              this.international_specialty, this.coepris_repssabi)
            .then(resp=>{
                if(resp.data.success){
                    this.resetForm();
                    this.snackText = "Documento guardado con éxito";
                    this.loadData();
                }
                else {
                    this.snackText = "Ha ocurrido un error!";
                }
            }).catch(()=>{
                this.snackText = "Ha ocurrido un error!";
            }).finally(()=>{
                this.saveChanges = false;
                this.snackBar = true;
            });
        }
    },
    mounted(){
        this.user = this.$store.getters.getUser;
    }
}
</script>
