<template>
    <v-alert
      v-model="alert"
      color="cyan"
      border="left"
      elevation="2"
      colored-border
      icon="mdi-file-document"
    >
      <div class="d-flex">
        {{  name  }}
        <v-icon class="mr-0 ml-auto icon-link" @click="goToLink">mdi-link-variant</v-icon>
      </div>  
    </v-alert>
</template>
<script>
export default {
    name: "ItemViewDocument",
    props: ["name", "url"],
    data(){
        return {
            alert: true
        }
    },
    methods: {
        goToLink() {
            window.open(this.url, '_blank');
        },
    }
}
</script>
<style>
.icon-link{
    cursor: pointer;
}
</style>
