<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="openDialog">
                mdi-file-document-multiple
            </v-icon>
        </template>

        <span>Documentos extras</span>

        <v-dialog v-model="dialiog"  fullscreen persistent>
            <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card-title class="text-h6 grey lighten-2">
                        <span class="headline">Documentos extras</span>
                        <v-btn plain icon @click="close" class="ml-auto mr-0">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="pt-2 pl-2 pr-2">
                        <div class="mt-4">
                                <v-col cols="12">
                                    <v-row>
                                        <v-col lg="8" md="8" sm="12">
                                            <v-row>
                                                <v-col cols="1" class="font-weight-bold"></v-col>
                                                <v-col cols="4" class="font-weight-bold">Nombre</v-col>
                                                <v-col cols="3" class="font-weight-bold">Subido por</v-col>
                                                <v-col cols="2" class="font-weight-bold">Subido</v-col>
                                                <v-col cols="2" class="font-weight-bold">Acciones</v-col>
                                            </v-row>
                                            <LoadDocuments v-if="loading"/>
                                            <v-row v-else v-for="itemImage in items" :key="itemImage.id">
                                                <v-col class="col-custom">
                                                <v-avatar v-if="itemImage.url" size="36px">
                                                    <v-img :src="itemImage.url" :lazy-src="itemImage.url"/>
                                                </v-avatar>
                                                <v-icon v-else size="36px">mdi-certificate-outline</v-icon>
                                                </v-col>
                                                <v-col cols="4" class="d-flex">
                                                    <label>{{ itemImage.name }}</label>
                                                </v-col>
                                                <v-col cols="3">{{itemImage.user.name}} {{itemImage.user.last_name}}({{ itemImage.user.roles }})</v-col>
                                                <v-col cols="2">{{ itemImage.created_at | formatDate }}</v-col>
                                                <v-col cols="2">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" small class="mr-2"
                                                            v-on:click="goToLink(itemImage.pdf_url)">
                                                        mdi-file-document
                                                    </v-icon>
                                                    </template>
                                                    <span>Descargar</span>
                                                </v-tooltip>
                                                <v-tooltip top v-if="user.roles == 'ADMIN' || user.roles == 'COPARMEX'">
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" small class="mr-2" v-on:click="deleteItemConfirm(itemImage)">
                                                        mdi-delete
                                                    </v-icon>
                                                    </template>
                                                    <span>Eliminar</span>
                                                </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col lg="4" md="4" sm="12">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field v-model="name" :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')" label="Nombre" :rules="[v=>!!v || 'Campo requerdo']">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-file-input
                                                        :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                        :rules="[v=>!!v || 'Campo requerdo']"
                                                        show-size
                                                        small-chips
                                                        label="Miniatura"
                                                        truncate-length="50"
                                                        v-model="file"
                                                    ></v-file-input>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-file-input
                                                        :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                        :rules="[v=>!!v || 'Campo requerdo']"
                                                        show-size
                                                        small-chips
                                                        label="Documento"
                                                        truncate-length="50"
                                                        v-model="filePDF"
                                                    ></v-file-input>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-btn :disabled="!valid || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')" 
                                                        :loading="saveChanges" 
                                                        color="primary" 
                                                        @click="save">
                                                        Guardar
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                        </div>
                    </v-card-text>
                </v-form>
            </v-card>
            <v-snackbar v-model="snackBar" :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs" icon @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-dialog>

        <v-dialog v-model="dialogConfirm" max-width="400px">
            <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                    <span class="headline">Confirmaci&oacute;n</span>
                    <v-btn plain icon @click="closeConfirm" class="ml-auto mr-0">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-2">
                    <span class="custom-text">¿Est&aacute;s seguro que desea eliminar este documento?</span>
                </v-card-text>   
                <v-card-actions>
                    <v-btn @click="closeConfirm" :disabled="saveChanges">
                        Cancelar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="deleteAction" color="red" :loading="saveChanges">
                        Confirmar
                    </v-btn>
                </v-card-actions> 
            </v-card>
        </v-dialog>
    </v-tooltip>
</template>
<script>
import doctorServices from "@/providers/DoctorsService";
import LoadDocuments from "@/components/load-documents/LoadDocuments.vue";
import moment from "moment";

export default {
    name: "ExtraDocumentsDoctor",
    props: ["item"],
    components: {
        LoadDocuments
    },
    data(){
        return {
            items: [],
            deleteItem: {},
            snackBar: false,
            snackText: "",
            loading: true,
            saveChanges: false,
            name: "",
            file: null,
            filePDF: null,
            dialiog: false,
            dialogConfirm: false,
            valid: false,
            user: {},
        }
    },
    filters: {
        formatDate(value) {
            moment.locale("es");
            return moment(value).format("DD/MM/YYYY");
        },
    },
    methods: {
        goToLink(link) {
            window.open(link, '_blank');
        },
        loadData(){
            this.loading = true;
            doctorServices.getRecord(this.item.id).then(resp=>{
                this.items = resp.value.doctor_documents;
            }).catch(()=>{
                this.snackText = "Ha ocurrido un error!";
                this.snackBar = true;
            }).finally(()=>{
                this.loading = false;
            })
        },
        openDialog(){
            this.dialiog = true;
            this.name = "";
            this.file = null;
            this.filePDF = null;
            this.loadData();
        },
        close(){
            this.dialiog = false;
            this.name = "";
            this.file = null;
            this.filePDF = null;
            this.valid = true;
        },
        resetForm(){
            this.name = "";
            this.file = null;
            this.filePDF = null;
            this.valid = true;
        },
        save(){
            this.$refs.form.validate();
            if (this.$refs.form.validate(true)) {
                this.saveChanges = true;
                doctorServices.uploadDocument(this.item.id, this.file, this.filePDF, this.name).then(resp=>{
                    if(resp.data.success){
                        this.resetForm();
                        this.snackText = "Documento guardado con éxito";
                        this.loadData();
                        this.valid = true;
                    }
                    else {
                        this.snackText = "Ha ocurrido un error!";
                    }
                }).catch(()=>{
                    this.snackText = "Ha ocurrido un error!";
                }).finally(()=>{
                    this.saveChanges = false;
                    this.snackBar = true;
                });
            }
        },
        deleteItemConfirm(item){
            this.deleteItem = item;
            this.dialogConfirm = true;
        },
        closeConfirm(){
            this.deleteItem = {};
            this.dialogConfirm = false;
        },
        deleteAction(){
            this.saveChanges = true;
            doctorServices.deleteDocument(this.deleteItem.id).then(resp => {
                if (resp.status === 200) {
                    this.loadData();
                    this.dialogConfirm = false;
                    this.snackText = "Certificación elminada con éxito";
                    this.closeConfirm();
                }
                else{
                    this.snackText = 'Ha ocurrido un error';
                }
            }).catch(() => {
                this.snackText = 'Ha ocurrido un error';
            }).finally(()=>{
                this.snackBar = true;
                this.saveChanges = false;
            })
        }
    },
    mounted(){
        this.user = this.$store.getters.getUser;
    }
}
</script>
<style>
.custom-text {
    font-size: 16px;
}
.col-custom{
    max-width: 60px;
}
</style>