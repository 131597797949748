<template>
    <v-row>
        <v-col cols="1">
            <v-skeleton-loader type="avatar"></v-skeleton-loader>
        </v-col>

        <v-col cols="4">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>        
        </v-col>

        <v-col cols="3">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>                            
        </v-col>

        <v-col cols="1">
            <v-skeleton-loader type="avatar"></v-skeleton-loader>
        </v-col>

        <v-col cols="4">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>        
        </v-col>

        <v-col cols="3">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>                            
        </v-col>

        <v-col cols="1">
            <v-skeleton-loader type="avatar"></v-skeleton-loader>
        </v-col>

        <v-col cols="4">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>        
        </v-col>

        <v-col cols="3">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>                            
        </v-col>

        <v-col cols="1">
            <v-skeleton-loader type="avatar"></v-skeleton-loader>
        </v-col>

        <v-col cols="4">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>        
        </v-col>

        <v-col cols="3">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>                            
        </v-col>

        <v-col cols="1">
            <v-skeleton-loader type="avatar"></v-skeleton-loader>
        </v-col>

        <v-col cols="4">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>        
        </v-col>

        <v-col cols="3">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>                            
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "LoadDocuments"
}
</script>
