<template>
  <v-container fill-height fluid text-xs-center>
    <v-card class="fill-height container--fluid" width="100vw" flat outlined>
      <v-data-table :headers="headers" :items="doctors" :items-per-page="10" :search="searchDoctor"
                    :loading="loadingData" :expanded="expanded" single-expand show-expand item-key="id"
                    no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere!"
                    class="elevation-1" :footer-props="{
          showFirstLastPage: true,
          disableItemsPerPage: false,
          itemsPerPageAllText: 'Todas',
          itemsPerPageText: 'Filas por página:',
        }">
        <template v-slot:top>
          <v-toolbar flat class="align-center">
            <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-1' : ''">
              M&eacute;dicos
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-switch v-model="filterPendings" class="pt-6">
              <template v-slot:label>
                Pendientes({{ countPending }})
              </template>
            </v-switch>
            <v-spacer></v-spacer>
            <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
              <v-text-field id="findtext-doctor" label="Buscar.." name="finddoctor" outlined dense single-line clearable
                            background-color="white" hide-details v-model="searchDoctor"
                            class="white--text mt-0 pt-0 mr-2"
                            prepend-inner-icon="mdi-magnify"></v-text-field>
            </v-responsive>
            <v-dialog class="Nuevo" :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialog" max-width="1000px"
                      persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :small="$vuetify.breakpoint.smAndDown" color="blue darken-1" dark depressed elevation="0"
                       class="mb-2" v-bind="attrs" v-on="on" @click="newItem" v-if="user.roles == 'ADMIN' || user.roles =='COPARMEX'">
                  Adicionar
                </v-btn>
              </template>
              <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-title class="text-h6 grey lighten-2">
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-container>
                      <v-row class="mt-2">
                        <v-col cols="10">
                          <v-text-field v-model="editedItem.name" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')" label="Nombre (*)" required
                                        :rules="[vtextRulesNombre]" :error-messages="erroresNombre"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-checkbox v-model="editedItem.active" :disabled="saveChanges" label="Activo"
                                      v-if="user.roles=='ADMIN'"></v-checkbox>
                        </v-col>


                        <v-col v-if="user.roles === 'ADMIN' || user.roles === 'CEETSB'" cols="5">
                          <v-col cols="12" md="6">
                            <v-select v-model="editedItem.state_ceetsb" dense
                                      :items="['Aprobado', 'Pendiente', 'Rechazado']"
                                      :rules="[v => !!v || 'Campo es obligatorio']"
                                      label="Excelencia"
                                      :disabled="saveChanges"
                                      item-color="primary"
                                      persistent-hint>
                            </v-select>
                          </v-col>
                        </v-col>
                        <v-col v-if="user.roles === 'ADMIN' || user.roles === 'CEETSB'" cols="7"
                               class="d-flex align-center justify-space-between">
                          <v-text-field dense v-model.number="editedItem.comment_ceetsb"
                                        :disabled="editedItem.state_ceetsb !== 'Rechazado' || saveChanges"
                                        label="Comentario" class="mt-3"></v-text-field>
                          <v-icon>mdi-file-document</v-icon>
                        </v-col>
                        <v-col v-if="user.roles === 'COPARMEX'" cols="5">
                          <v-col cols="12" md="6">
                            <v-select v-model="editedItem.state_ceetsb" dense
                                      :items="['Aprobado', 'Pendiente', 'Rechazado']"
                                      :rules="[v => !!v || 'Campo es obligatorio']"
                                      label="Excelencia"
                                      :disabled="saveChanges"
                                      item-color="primary"
                                      persistent-hint>
                            </v-select>
                          </v-col>
                        </v-col>
                        <v-col v-if="user.roles === 'COPARMEX'" cols="7"
                               class="d-flex align-center justify-space-between">
                          <v-text-field dense v-model.number="editedItem.comment_ceetsb"
                                        :disabled="editedItem.state_ceetsb !== 'Rechazado' || saveChanges"
                                        label="Comentario" class="mt-3"></v-text-field>
                          <v-icon>mdi-file-document</v-icon>
                        </v-col>

                        <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COEPRIS'" cols="5">
                          <v-col cols="12" md="6">
                            <v-select v-model="editedItem.state_coepris" dense
                                      :items="['Aprobado', 'Pendiente', 'Rechazado']"
                                      :rules="[v => !!v || 'Campo es obligatorio']"
                                      label="Repssabi"
                                      :disabled="saveChanges"
                                      item-color="primary"
                                      persistent-hint>
                            </v-select>
                          </v-col>
                        </v-col>
                        <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COEPRIS'" cols="7"
                               class="d-flex align-center justify-space-between">
                          <v-text-field dense v-model.number="editedItem.comment_coepris"
                                        :disabled="editedItem.state_coepris !== 'Rechazado' || saveChanges"
                                        label="Comentario" class="mt-3"></v-text-field>
                          <v-icon>mdi-file-document</v-icon>
                        </v-col>
                        <v-col v-if="user.roles === 'COPARMEX'" cols="5">
                          <v-col cols="12" md="6">
                            <v-select v-model="editedItem.state_coepris" dense
                                      :items="['Pendiente', 'Rechazado']"
                                      :rules="[v => !!v || 'Campo es obligatorio']"
                                      label="Repssabi"
                                      :disabled="saveChanges"
                                      item-color="primary"
                                      persistent-hint>
                            </v-select>
                          </v-col>
                        </v-col>
                        <v-col v-if="user.roles === 'COPARMEX'" cols="7"
                               class="d-flex align-center justify-space-between">
                          <v-text-field dense v-model.number="editedItem.comment_coepris"
                                        :disabled="editedItem.state_coepris !== 'Rechazado' || saveChanges"
                                        label="Comentario" class="mt-3"></v-text-field>
                          <v-icon>mdi-file-document</v-icon>
                        </v-col>

                        <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COPARMEX'" cols="5">
                          <v-col cols="12" md="6">
                            <v-select v-model="editedItem.state_coparmex" dense
                                      :items="['Aprobado', 'Pendiente', 'Rechazado']"
                                      :rules="[v => !!v || 'Campo es obligatorio']"
                                      label="Coparmex"
                                      :disabled="saveChanges"
                                      item-color="primary"
                                      persistent-hint>
                            </v-select>
                          </v-col>
                        </v-col>
                        <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COPARMEX'" cols="7"
                               class="d-flex align-center justify-space-between">
                          <v-text-field dense v-model.number="editedItem.comment_coparmex"
                                        :disabled="editedItem.state_coparmex !== 'Rechazado' || saveChanges"
                                        label="Comentario" class="mt-3"></v-text-field>
                          <v-icon>mdi-file-document</v-icon>
                        </v-col>

                        <!--                        <v-col cols="3">-->
                        <!--                          <v-checkbox v-model="editedItem.excellence" :disabled="saveChanges" v-if="user.roles == 'CEETSB' || user.roles == 'ADMIN'" label="Excelencia"></v-checkbox>-->
                        <!--                        </v-col>-->

                        <!--                        <v-col cols="3">-->
                        <!--                          <v-checkbox v-model="editedItem.repsabi" :disabled="saveChanges" v-if="user.roles == 'COEPRIS' || user.roles == 'ADMIN'" label="Repssabi"></v-checkbox>-->
                        <!--                        </v-col>-->

                        <!--                        <v-col cols="3">-->
                        <!--                            <v-checkbox v-model="editedItem.coparmex" :disabled="saveChanges" v-if="user.roles == 'COPARMEX' || user.roles == 'ADMIN'" label="Coparmex"></v-checkbox>-->
                        <!--                        </v-col>-->

                        <v-col cols="3">
                          <v-checkbox v-model="editedItem.outstanding" label="Destacado"
                                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"></v-checkbox>
                        </v-col>

                        <v-col cols="3">
                          <v-checkbox v-model="editedItem.premium" label="Premium" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"></v-checkbox>
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox v-model="editedItem.member" label="Miembro" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"></v-checkbox>
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox v-model="editedItem.basic" label="Básico" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"></v-checkbox>
                        </v-col>

                        <v-col cols="12">
                          <v-text-field dense v-model.number="editedItem.register_number" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        :error="!editedItem.register_number" label="Número único de Identificación (*)"
                                        required
                          >
                          </v-text-field>

                          <v-combobox v-model="editedItem.address" :items="places" :loading="loadingPlaces"
                                      :search-input.sync="searchQuery" no-data-text="No hay resultados de búsqueda!"
                                      item-text="place_name" no-filter clearable label="Dirección de residencia (*)"
                                      required
                                      :rules="[vtextRulesDireccion]" :error-messages="erroresDireccion"
                                      v-on="$listeners"
                                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                      v-on:change="getSelectedItem" @click:clear="onClear">
                          </v-combobox>
                          <v-text-field v-model="editedItem.email" label="E-mail" :rules="emailRules"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-textarea v-model="editedItem.description" label="Descripción - Español (*)" required
                                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                      :rules="textRulesDescripcion" no-resize rows="6" :error="!editedItem.description"
                                      counter>
                          </v-textarea>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-textarea v-model="editedItem.description_english" label="Descripción - Inglés (*)" required
                                      :rules="textRulesDescripcion" no-resize rows="6"
                                      :error="!editedItem.description_english"
                                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                      counter>
                          </v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field-simplemask v-model="editedItem.phone1"
                                                   label="Teléfono MX (*)" v-bind:properties="{
                              dense: true,
                              prefix: '+52',
                              suffix: '',
                              readonly: false,
                              disabled: false,
                              outlined: false,
                              clearable: false,
                              disabled: saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX'),
                              placeholder: '',
                              error: !editedItem.phone1,
                              rules: [v => !!v || 'Este campo es obligatorio!',
                                      v => /^\(\d{3}\) \d{3}-\d{4}$/.test(v) || 'El campo debe contener un número de teléfono válido y seguir el formato +52XXXXXXXXXX'],
                              messageError: erroresTelefonoMexico
                            }" v-bind:options="{
                              inputMask: '(###) ###-####',
                              outputMask: '##########',
                              empty: null,
                              applyAfter: false,
                              alphanumeric: false,
                              lowerCase: false,
                            }" v-bind:focus="focus" v-on:focus="focus = false" required/>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field-simplemask v-model="editedItem.phone2"
                                                   label="Teléfono USA" v-bind:properties="{
                              dense: true,
                              prefix: '+1',
                              suffix: '',
                              readonly: false,
                              disabled: false,
                              outlined: false,
                              clearable: false,
                              disabled: saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX'),
                              placeholder: '',
                              rules: textRulesTelefonoUSA
                            }" v-bind:options="{
                              inputMask: '(###) ###-####',
                              outputMask: '##########',
                              empty: null,
                              applyAfter: false,
                              alphanumeric: false,
                              lowerCase: false,
                            }" v-bind:focus="focus" v-on:focus="focus = false"/>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select dense v-model="editedItem.city_id" :items="cities" item-text="name" item-value="id"
                                    clearable label="Ciudad (*)" required placeholder="Ciudad"
                                    :error="!editedItem.city_id"
                                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                    :rules="[v => !!v || 'Este campo es obligatorio']"></v-select>
                        </v-col>
                        <!--                        <v-col cols="12" md="6" v-if="user.roles == 'ADMIN'">-->
                        <!--                           <v-select v-model="editedItem.state" dense-->
                        <!--                                     :items="['Aprobado', 'Pendiente', 'Rechazado']"-->
                        <!--                                     :rules="[v => !!v || 'Campo es obligatorio']"-->
                        <!--                                     label="Estado"-->
                        <!--                                     :disabled="saveChanges"-->
                        <!--                                     item-color="primary"-->
                        <!--                                     persistent-hint>-->
                        <!--                                    </v-select>-->
                        <!--                        </v-col>-->
                        <!--                        <v-col cols="12" md="6" v-if="user.roles == 'ADMIN'">-->
                        <!--                          <v-text-field dense v-model.number="editedItem.comment" :disabled="editedItem.state != 'Rechazado' || saveChanges"-->
                        <!--                            label="Comentario"></v-text-field>-->
                        <!--                        </v-col>-->
                        <!--                        <v-col cols="12" md="6" v-if="user.roles == 'COPARMEX'">-->
                        <!--                           <v-select v-model="editedItem.state_coparmex" dense-->
                        <!--                                     :items="['Aprobado', 'Pendiente', 'Rechazado']"-->
                        <!--                                     :rules="[v => !!v || 'Campo es obligatorio']"-->
                        <!--                                     label="Estado"-->
                        <!--                                     :disabled="saveChanges"-->
                        <!--                                     item-color="primary"-->
                        <!--                                     persistent-hint>-->
                        <!--                                    </v-select>-->
                        <!--                        </v-col>-->
                        <!--                        <v-col cols="12" md="6" v-if="user.roles == 'COPARMEX'">-->
                        <!--                          <v-text-field dense v-model.number="editedItem.comment_coparmex" :disabled="editedItem.state_coparmex != 'Rechazado' || saveChanges"-->
                        <!--                            label="Comentario"></v-text-field>-->
                        <!--                        </v-col>-->
                        <!--                        <v-col cols="12" md="6" v-if="user.roles == 'CEETSB'">-->
                        <!--                           <v-select v-model="editedItem.state_ceetsb" dense-->
                        <!--                                     :items="['Aprobado', 'Pendiente', 'Rechazado']"-->
                        <!--                                     :rules="[v => !!v || 'Campo es obligatorio']"-->
                        <!--                                     label="Estado"-->
                        <!--                                     :disabled="saveChanges"-->
                        <!--                                     item-color="primary"-->
                        <!--                                     persistent-hint>-->
                        <!--                                    </v-select>-->
                        <!--                        </v-col>-->
                        <!--                        <v-col cols="12" md="6" v-if="user.roles == 'CEETSB'">-->
                        <!--                          <v-text-field dense v-model.number="editedItem.comment_ceetsb" :disabled="editedItem.state_ceetsb != 'Rechazado' || saveChanges"-->
                        <!--                            label="Comentario"></v-text-field>-->
                        <!--                        </v-col>-->
                        <!--                        <v-col cols="12" md="6" v-if="user.roles == 'COEPRIS'">-->
                        <!--                           <v-select v-model="editedItem.state_coepris" dense-->
                        <!--                                     :items="['Aprobado', 'Pendiente', 'Rechazado']"-->
                        <!--                                     :rules="[v => !!v || 'Campo es obligatorio']"-->
                        <!--                                     label="Estado"-->
                        <!--                                     :disabled="saveChanges"-->
                        <!--                                     item-color="primary"-->
                        <!--                                     persistent-hint>-->
                        <!--                                    </v-select>-->
                        <!--                        </v-col>-->
                        <!--                        <v-col cols="12" md="6" v-if="user.roles == 'COEPRIS'">-->
                        <!--                          <v-text-field dense v-model.number="editedItem.comment_coepris" :disabled="editedItem.state_coepris != 'Rechazado' || saveChanges"-->
                        <!--                            label="Comentario"></v-text-field>-->
                        <!--                        </v-col>-->
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model.number="editedItem.web_page" label="Página Web"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        :rules="textRulesUrlWeb"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model.number="editedItem.profesional_license_number"
                                        label="Número de Licencia Profesional (*)" required 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        :error="!editedItem.profesional_license_number"
                                        :rules="rulesLicense"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model.number="editedItem.specialty_license_number"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Número de Licencia de la Especialidad"
                                        :rules="textRulesEspecialidad"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field dense v-model.number="editedItem.certification_validity" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Número de Certificación Válida"
                                        :rules="textRulesCertificacion"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field dense v-model.number="editedItem.certificate_number" label="Certificado Número"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        :rules="textRulesCertificacion"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field dense v-model.number="editedItem.professional_license_verification"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        :rules="textRulesLicenseProfessional"
                                        label="Verificación de Licencia Profesional"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field dense v-model="editedItem.facebook_profile" label="Perfil en Facebook"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        :rules="textRulesFacebookUrl"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field dense v-model="editedItem.twitter_profile" label="Cuenta de X"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        :rules="textRulesXUrl"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field dense v-model="editedItem.instagram_profile" label="Perfil en Instagram"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        :rules="textRulesInstagramUrl"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field dense v-model="editedItem.tiktok" label="Perfil de Tiktok"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        :rules="textRulesTiktokUrl"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="8">
                          <v-text-field dense v-model="editedItem.servicehours"
                                        label="Horarios de Atención [hh:mm am/pm - hh:mm am/pm]"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                          ></v-text-field>
                        </v-col>
                        <v-col col="12">
                          <v-select dense v-model="editedItem.specialties" :items="specialtiesList" item-text="name"
                                    item-value="id" return-object label="Especialidades (*)" required multiple
                                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX') || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                    item-color="primary" small-chips hint="Selecciones las especialidades del médico"
                                    persistent-hint :rules="[vtextRulesEspecialidad]"
                                    :error-messages="erroresEspecialidad">
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-select dense v-model="editedItem.gender" label="Género del Representante legal"
                                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                    :items="['Femenino', 'Masculino', 'Prefiero no decir', 'Otro']"></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.rfc" label="RFC" maxlength="13"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.full_name" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Nombre completo de la persona física o moral que presta o prestará el o los servicios"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.ret" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Número de folio de Registro Estatal de Turismo (RET) de Baja California. (si aplica)"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.postal_code" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Código postal"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.colonia" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Colonia"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.state_country" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Estado"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.city_country" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Ciudad"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.street" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Calle"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model.number="editedItem.num_ext" type="number" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Número exterior"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model.number="editedItem.num_int" type="number" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Número interior"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.municipality"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Municipio"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.whatsapp" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Whatsapp"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.extra_services" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Servicios extra"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select dense v-model="editedItem.speak_english"
                                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                    label="¿Cuenta con personal que hable ingés?"
                                    :items="['Sí', 'No']"></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select dense v-model="editedItem.english" 
                                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                    label="Proficiencia de inglés convencional"
                                    :items="['Básico', 'Intermendio', 'Avanzado', 'Bilingüe']"></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.professional_organism" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Si es miembro vigente de algún organismo profesional estatal, nacional o internacional, favor de indicar el o los nombres"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select dense v-model="editedItem.integral_services" 
                                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                    label="¿Ofrece algún servicio integral? es decir… transporte desde su arribo al aeropuerto, hospedaje y/o alimentación, adicional al tratamiento requerido"
                                    :items="['Sí', 'No', 'No, pero me interesa hacer sinergía con los prestadores de servicios turísticos', 'No, pero me interesa hacer sinergía con los prestadores de servicios turísticos']"></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.extra_services_tourism" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Si contestó que sé la pregunta anterior, favor de indicar el servicio turístico que ofrece adicional al tratamiento requerido"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.tourism_name" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Nombre comercial del servidor turístico con quien tiene la alianza comercial (si aplica)"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select dense v-model="editedItem.register_repssabi" 
                                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                    label="Favor de indicar si cuenta con la constancia del Registro Estatal de establecimientos prestadores de servicios de salud y bienestar (REPSSABI) del la COEPRIS"
                                    :items="['Sí', 'No', 'En trámite']"></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.repssabi_folio" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Favor de indicar el número de folio de la constancia del REPSSABI de la COEPRIS"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.repssabi_expire_date" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Favor de indicar la fecha de vencimiento de la constancia de REPSSABI de la COEPRIS"
                                        type="date"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model="editedItem.conacem_date" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Favor de indicar la fecha de emisión de la constancia de la especialidad médica del solicitante (emitida por la CONACEM)"
                                        type="date"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense v-model.number="editedItem.conacem_expire_date" 
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Favor de indicar la fecha de vencimiento de la constancia de la especialidad médica del solicitante (emitida por la CONACEM)"
                                        type="date"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <VideoAdd :videos="editedItem.videos" @updateVideo="updateVideo" :action="editedIndex === -1?'ADD':'UPDATE'"></VideoAdd>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :disabled="saveChanges" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn :disabled="!valid" :loading="saveChanges" color="primary" @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline">
                  ¿Est&aacute; seguro que desea eliminar este elemento?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" :disabled="saveChanges" text @click="closeDelete">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" :loading="saveChanges" @click="deleteItemConfirm">
                    Proceder
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDeletePicture" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline">
                  ¿Desea eliminar esta Imagen de la Galer&iacute;a?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" :disabled="saveChanges" text @click="closeDeletePicture">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" :loading="saveChanges" @click="deleteItemConfirmPicture">
                    Proceder
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDeleteQr" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline">
                  ¿Desea eliminar este QR?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" :disabled="saveChanges" text @click="closeDeleteQr">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" :loading="saveChanges" @click="deleteItemConfirmQr">
                    Proceder
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogError" persistent max-width="500">
              <v-card>
                <v-card-title class="text-h6 primary lighten-2">
                  <span class="headline">Error de Validaci&oacute;n</span>
                </v-card-title>

                <v-card-text>
                  <div class="text-h6 pa-12">{{ messageError }}!</div>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn text @click.stop="closeDialogError"> Cerrar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialogGalleryPhotos" persistent width="90%">
              <v-card>
                <v-toolbar dark color="primary">
                  <v-btn class="hidden-sm-and-down" icon dark @click="dialogGalleryPhotos = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>Administrador de Im&aacute;genes</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn dark text @click="dialogGalleryPhotos = false">
                      Cerrar
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-container fill-height bottom-gradient fluid class="ma-0">
                  <v-row>
                    <v-col>
                      <div class="fill-height">
                        <!--v-model="model"-->
                        <v-slide-group center-active class="pa-0" next-icon="mdi-arrow-right-drop-circle-outline"
                                       prev-icon="mdi-arrow-left-drop-circle-outline" show-arrows>
                          <v-slide-item v-for="itemImage in editedItem.pictures" :key="itemImage.id"
                                        v-slot="{ active, toggle }">
                            <v-card :color="active ? undefined : 'white'" class="ma-4 rounded-lg" height="auto"
                                    width="250" elevation="5" @click="toggle">
                              <v-img :src="
                                  itemImage.url != null
                                    ? itemImage.url
                                    : require('@/assets/images/no-image.jpg')
                                " :lazy-src="
                                  require('@/assets/images/no-image.jpg')
                                " gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" aspect-ratio="1" height="250"
                                     class="rounded-lg white--text align-end justify-start text-end elevation-10">
                                <v-btn dark icon elevation="0" class="ma-2" v-on:click="deletePicture(itemImage)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>

                                <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-card>
                          </v-slide-item>
                        </v-slide-group>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <div class="container mt-0 pt-0">
                  <v-form v-model="validFile" lazy-validation>
                    <v-file-input v-model="selectedFiles" small-chips accept="image/png, image/jpeg, image/bmp"
                                  show-size multiple clearable label="Agregar ficheros" @change="inputChanged">
                      <template v-slot:selection="{ index, text }">
                        <v-chip small text-color="white" color="#295671" close @click:close="removeChip(index)">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                    <div v-if="imageFiles">
                      <h5>Archivos seleccionados</h5>
                      <v-chip v-for="(f, i) in imageFiles" :key="i" class="mr-1">
                        {{ f.name }}
                      </v-chip>
                      <div class="py-3">
                        <v-btn dark :disabled="!validFile" color="primary" @click="uploadPictures()"
                               :loading="saveChanges">Actualizar
                          Galer&iacute;a
                        </v-btn>
                      </div>
                    </div>
                  </v-form>
                </div>
              </v-card>
            </v-dialog>

            <!-- qr -->

            <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialogQrCode" persistent width="90%">
              <v-card>
                <v-toolbar dark color="primary">
                  <v-btn class="hidden-sm-and-down" icon dark @click="dialogQrCode = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title class="text-subtitle-1 text-md-h5">Administrador de Qr</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn dark text @click="dialogQrCode = false">
                      Cerrar
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-container fill-height bottom-gradient fluid class="ma-0">
                  <v-row>
                    <v-col col="6">
                      <div class="fill-height">
                        <!--v-model="model"-->
                        <v-slide-group center-active class="pa-0" next-icon="mdi-arrow-right-drop-circle-outline"
                                       prev-icon="mdi-arrow-left-drop-circle-outline" show-arrows>
                          <v-slide-item key="qr" v-slot="{ active, toggle }" v-if="editedItem.coepris_qr">
                            <v-card :color="active ? undefined : 'white'" class="ma-4 rounded-lg" height="auto"
                                    width="250" elevation="5" @click="toggle">
                              <v-img :src="
                                  editedItem.coepris_qr != null
                                    ? editedItem.coepris_qr
                                    : require('@/assets/images/no-image.jpg')
                                " :lazy-src="
                                  require('@/assets/images/no-image.jpg')
                                " gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" aspect-ratio="1" height="250"
                                     class="rounded-lg white--text align-end justify-start text-end elevation-10">
                                <v-btn dark icon elevation="0" class="ma-2"
                                       v-on:click="deleteQr(editedItem.coepris_qr)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>

                                <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-card>
                          </v-slide-item>
                        </v-slide-group>

                        <div v-if="editedItem.coepris_qr">
                          <h4>Link del qr</h4>

                          <v-text-field dense v-model="editedItem.coepris_qr_link"></v-text-field>
                        </div>
                      </div>
                    </v-col>

                    <v-col col="6" v-if="editedItem.coepris_qr">
                      <div>
                        <h4>Vencimiento del qr</h4>

                        <v-date-picker v-model="editedItem.vencimiento_fecha" locale="es" scrollable></v-date-picker>
                      </div>

                      <h3 class="mt-2">
                        {{ editedItem.vencimiento_fecha }}
                      </h3>
                    </v-col>
                  </v-row>

                  <v-col col="12" v-if="editedItem.coepris_qr">
                    <div style="display: flex; justify-content: center">
                      <v-btn @click="updateVencimientoFecha(editedItem)" :loading="saveChanges">
                        Guardar datos
                      </v-btn>
                    </div>
                  </v-col>
                </v-container>
                <div class="container mt-0 pt-0">
                  <v-file-input v-model="selectedFiles" small-chips accept="image/png, image/jpeg, image/bmp" show-size
                                multiple clearable label="Agregar ficheros" @change="inputChanged">
                    <template v-slot:selection="{ index, text }">
                      <v-chip small text-color="white" color="#295671" close @click:close="removeChip(index)">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                  <div v-if="imageFiles">
                    <h5>Archivos seleccionados</h5>
                    <v-chip v-for="(f, i) in imageFiles" :key="i" class="mr-1">
                      {{ f.name }}
                    </v-chip>
                    <div class="py-3">
                      <v-btn dark color="primary" :loading="saveChanges" @click="uploadQr()">Actualizar Qr</v-btn>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-dialog>

            <!-- //////////////////////////////////////////// -->
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip top v-if="user.roles == 'ADMIN' || user.roles =='COPARMEX'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="loadGalleryImages(item)">
                mdi-camera
              </v-icon>
            </template>
            <span>Administrar Im&aacute;genes</span>
          </v-tooltip>

          <v-tooltip top v-if="user.roles == 'ADMIN' || user.roles =='COPARMEX'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="loadQrCode(item)">
                mdi-qrcode
              </v-icon>
            </template>
            <span>Coepris QR</span>
          </v-tooltip>

          <FormDocumentsDoctor :item="item"/>

          <Keywords v-if="user.roles == 'ADMIN' || user.roles =='COPARMEX'" :entity="item" :url="urlServices" @reload="getDoctors"/>

          <Certification v-if="user.roles == 'ADMIN' || user.roles =='COPARMEX'" :entity="item" :url="urlServicesCertification" :deleteUrl="deleteUrlServicesCertification"
                         @reload="getDoctors"/>
                         
          <ExtraDocumentsDoctor v-if="user.roles == 'ADMIN' || user.roles =='COPARMEX'" :item="item"/>

          <HistorialChanges :item="item" :roles="user.roles | filterRoles"/>

          <v-tooltip top v-if="user.roles == 'ADMIN' || user.roles =='COPARMEX'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:item.specialties="{ item }">
          {{ speciality(item.specialties) }}
        </template>
        <template v-slot:item.city="{ item }">
          {{ item.city.name }}
        </template>
        <template v-slot:item.active="{ item }">
          <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.active))">
            {{ fixStateLabelExtra(item.active) }}
          </div>
        </template>
        <template v-slot:item.coparmex="{ item }">
          <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.state_coparmex))">
            {{ fixStateLabelExtra(item.state_coparmex) }}
          </div>
        </template>
        <template v-slot:item.excellence="{ item }">
          <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.state_ceetsb))">
            {{ fixStateLabelExtra(item.state_ceetsb) }}
          </div>
        </template>
        <template v-slot:item.repsabi="{ item }">
          <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.state_coepris))">
            {{ fixStateLabelExtra(item.state_coepris) }}
          </div>
        </template>
        <template v-slot:item.outstanding="{ item }">
          <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.outstanding))">
            {{ fixStateLabelYesOrNot(item.outstanding) }}
          </div>
        </template>
        <template v-slot:item.premium="{ item }">
          <div class="custom-state-div custom-circle" :style="fixStateColor(fixStateLabelYesOrNot(item.premium))">
            {{ fixStateLabelYesOrNot(item.premium) }}
          </div>
        </template>
        <template v-slot:item.member="{ item }">
          <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.member))">
            {{ fixStateLabelYesOrNot(item.member) }}
          </div>
        </template>
        <template v-slot:item.basic="{ item }">
          <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.basic))">
            {{ fixStateLabelYesOrNot(item.basic) }}
          </div>
        </template>
        <template v-slot:item.count="{ item }">
          {{ item.historials_count }}
        </template>
        <template v-slot:no-data>
          <span class="text-h5">
            ¡No hay registros en el listado!</span>
          <br/>
          <v-btn color="primary" @click="getDoctors"> Recargar</v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container fluid>
              <v-row justify="start" class="ma-1">
                <h3>{{ item.name }}</h3>
              </v-row>
              <v-row class="ma-1">
                <v-col cols="12" md="10" class="picture-list">
                  <v-row display="inline-block">
                    <v-img :src="
                        item.pictures[0] != null
                          ? item.pictures[0].url
                          : require('@/assets/images/no-image.jpg')
                      " :lazy-src="require('@/assets/images/no-image.jpg')" max-height="250" max-width="250"
                           aspect-ratio="1" class="white--text align-center justify-center elevation-2 picture-list">
                    </v-img>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                    <v-col cols="2"> Direcci&oacute;n:</v-col>
                    <v-col>
                      {{ item.address }}
                    </v-col>
                  </v-row>
                  <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                    <v-col cols="2"> Email:</v-col>
                    <v-col>
                      {{ item.email }}
                    </v-col>
                  </v-row>
                  <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                    <v-col cols="2"> Sitio Web:</v-col>
                    <v-col>
                      {{ item.web_page }}
                    </v-col>
                  </v-row>
                  <v-row :dense="$vuetify.breakpoint.smAndDown" justify="start">
                    <v-col cols="5">
                      Tel&eacute;fono MX:
                      <a target="_blank" v-if="item.phone1!== 'null'" :href="'tel:+52' + item.phone1">+52
                        {{ item.phone1 | formatPhone }}</a>
                      <span v-else :href="'#'">No cuenta con número de teléfono mexicano</span>
                    </v-col>
                  </v-row>
                  <v-row :dense="$vuetify.breakpoint.smAndDown" justify="start">
                    <v-col cols="5">
                      Tel&eacute;fono USA:
                      <a target="_blank" v-if="item.phone2!== 'null'" :href="'tel:+1' + item.phone2">+1
                        {{ item.phone2 | formatPhone }}</a>
                      <span v-else :href="'#'">No cuenta con número de teléfono americano</span>
                    </v-col>
                  </v-row>
                </v-col>

              </v-row>
              <v-row :dense="$vuetify.breakpoint.smAndDown">
                <v-col cols="4">
                  N&uacute;mero de Licencia Profesional:
                  {{ item.profesional_license_number }}
                </v-col>
                <v-col cols="4">
                  N&uacute;mero de Licencia de la Especialidad:
                  {{ item.specialty_license_number }}
                </v-col>
                <v-col cols="4">
                  N&uacute;mero de Certificaci&oacute;n V&aacute;lida:
                  {{ item.certification_validity }}
                </v-col>
                <v-col cols="4">
                  Certificado N&uacute;mero: {{ item.certificate_number }}
                </v-col>
                <v-col cols="4">
                  Verificaci&oacute;n de Licencia Profesional:
                  {{ item.professional_license_verification }}
                </v-col>
                <v-col cols="4">
                  Perfil en Facebook:
                  <a target="_blank" v-if="item.facebook_profile" :href="item.facebook_profile">Ir a la red social</a>
                  <span v-else :href="'#'">No cuenta con esta red social</span>
                </v-col>
                <v-col cols="4">
                  Cuenta de X:
                  <a target="_blank" v-if="item.twitter_profile" :href="item.twitter_profile">Ir a la red social</a>
                  <span v-else :href="'#'">No cuenta con esta red social</span>
                </v-col>
                <v-col cols="4">
                  Perfil en Instagram:
                  <a target="_blank" v-if="item.instagram_profile" :href="item.instagram_profile">Ir a la red social</a>
                  <span v-else :href="'#'">No cuenta con esta red social</span>
                </v-col>
                <v-col cols="4">
                  Canal de Youtube:
                  <div v-if="item.videos && item.videos.length > 0">
                    <a target="_blank" v-for="video in item.videos" :key="video.id" :href="video.url">Ir a la red social</a>
                  </div>
                  <span v-else :href="'#'">No cuenta con esta red social</span>
                </v-col>
                <v-col cols="4">
                  Perfil de Tiktok:
                  <a target="_blank" v-if="item.tiktok" :href="item.tiktok">Ir a la red social</a>
                  <span v-else :href="'#'">No cuenta con esta red social</span>
                </v-col>
                <v-col cols="4">
                  Horarios de Atenci&oacute;n: {{ item.servicehours }}
                </v-col>
              </v-row>
              <v-row class="mx-1"></v-row>
              <v-row class="mx-1"></v-row>
              <v-row class="mx-1"></v-row>
              <v-row class="mx-1"></v-row>
              <v-row class="mx-1"></v-row>
              <v-row class="mx-1"></v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>
      <v-snackbar v-model="snackBar" :timeout="2000">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" icon @click="snackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import doctorsService from "@/providers/DoctorsService";
import citiesService from "@/providers/CitiesService";
import certificationsService from "@/providers/CertificationsService";
import specialtiesService from "@/providers/SpecialtiesService";
import Keywords from "@/components/keywords/Keywords";
import Certification from "@/components/certifications/Certification";
import {changeInformationSEO} from "@/providers/KeywordsServices";
import VideoAdd from "@/components/admin/modules/components/VideoAdd";
import HistorialChanges from "@/components/historial-changes/HistorialChanges";
import ExtraDocumentsDoctor from "@/components/extra-documents-doctor/ExtraDocumentsDoctor";
import FormDocumentsDoctor from "@/components/form-documents-doctor/FormDocumentsDoctor";

export default {
  name: "DoctorsModuleComponent",
  components: {
    HistorialChanges,
    VideoAdd,
    Keywords,
    Certification,
    ExtraDocumentsDoctor,
    FormDocumentsDoctor
  },
  props: {
    title: String,
    breadcrumbs: Array,
    icon: String,
  },
  data: () => ({
    // MapBox Location
    placeNameLimit: 60,
    selectedPlace: {
      place_name: "",
      center: [],
    },
    placesList: [],
    loadingPlaces: false,
    searchQuery: null,
    mapBoxApiKey: process.env.VUE_APP_BHC_MAPBOX_API_KEY,
    // Default data
    focus: false,
    snackBar: false,
    snackText: "",
    valid: false,
    searchDoctor: "",
    loadingData: false,
    dialog: false,
    dialogDelete: false,
    dialogError: false,
    dialogDeletePicture: false,
    dialogGalleryPhotos: false,
    currentPicture: undefined,
    selectedFiles: [],
    imageFiles: [],
    messageError: "",
    associationsList: [],
    certificationsList: [],
    specialtiesList: [],
    doctors: [],
    cities: [],
    validFile: false,
    expanded: [],
    user: {},
    headers: [
      {text: "", value: "data-table-expand", width: 5},
      {text: "Id", value: "id", align: " d-none"},
      {
        text: "Nombre",
        align: "start",
        value: "name",
        width: 260,
      },
      {
        text: "Especialidad",
        align: "start",
        value: "specialties",
        width: 170,
      },
      {
        text: "Municipio",
        align: "start",
        value: "city",
        width: 140,
      },
      {
        text: "E-mail",
        value: "email"
      },
      {
        text: "Activo",
        value: "active",
        width: 100,
      },
      {
        text: "Coparmex",
        value: "coparmex",
        width: 120,
      },
      {
        text: "Excelencia",
        value: "excellence",
        width: 118,
      },
      {
        text: "Repssabi",
        value: "repsabi",
        width: 120,
      },
      {
        text: "Destacado",
        value: "outstanding",
        width: 118,
      },
      {
        text: "Premium",
        value: "premium",
        width: 110,
      },
      {
        text: "Miembro",
        value: "member",
        width: 110,
      },
      {
        text: "Básico",
        value: "basic",
        width: 110,
      },
      {
        text: "Visitas",
        value: "count",
        width: 100,
      },
      {text: "Acciones", value: "actions", width: 105, sortable: false},
    ],

    //:error-messages="erroresNumeroCantidadExternos"
    //erroresNumeroCantidadExternos: [],
    //this.erroresNumeroCantidadExternos = ['Campo Vacío.'];

    //validaciones
    erroresNombre: [],
    erroresNoIdentificacion: [],
    erroresDireccion: [],
    erroresTelefonoMexico: [],
    erroresCiudad: [],
    erroresLicencia: [],
    erroresEspecialidad: [],

    textRulesNombre: [
      (v) => !!v || "Este campo es obligatorio!",
      (v) => /^[a-zA-Z .ñáéíóúÁÉÍÓÚüÜ]+$/.test(v) || "El campo debe contener solo letras, el espacio y el punto",
    ],
    textRulesNoIdentificacion: [
      (v) => !!v || "Este campo es obligatorio!",
      (v) => v === '' || /^\d{13}$/.test(v) || "El campo debe contener exactamente 13 dígitos numéricos",
    ],
    textRulesDireccion: [
      (v) => !!v || "Este campo es obligatorio!",
      //(v) => /^[a-zA-Z0-9 ,._/-()#ñáéíóúÁÉÍÓÚüÜ]+$/.test(v) || "El campo debe contener caracteres, letras y números",
      //(v) => v.length <= 100 || "El nombre no puede exceder los 100 caracteres.",
    ],
    textRulesDescripcion: [
      (v) => !!v || "Este campo es obligatorio!"
      //(v) => /^[a-zA-Z0-9 ,._/-ñáéíóúÁÉÍÓÚüÜ]+$/.test(v) || "El campo debe contener caracteres, letras y números",
    ],
    textRulesUrlWeb: [
      (v) => v === '' || /^(https?:\/\/[^\s$.?#].[^\s]*)$/.test(v) || "El campo debe contener una URL válida que comience con http:// o https://",
    ],
    /*textRulesTelefonoMexico: [
      (v) => !!v || "Este campo es obligatorio!",
      (v) => /^(?:\+1\d{10}|\+52\d{10})$/.test(v) || "El campo debe contener un número de teléfono válido y seguir el formato +1XXXXXXXXXX o +52XXXXXXXXXX",
    ],*/
    textRulesTelefonoMexico: [
      (v) => !!v || "Este campo es obligatorio!",
      (v) => v !== '+52' || "Este campo no puede ser solo '+52'.",
      (v) => /^(\+52\d{10})$/.test(v) || "El campo debe contener un número de teléfono válido y seguir el formato +52XXXXXXXXXX.",
    ],
    /*textRulesTelefonoUSA: [
      (v) => v === '' || /^(?:\+1\d{10}|\+52\d{10})$/.test(v) || "El campo debe contener un número de teléfono válido y seguir el formato +1XXXXXXXXXX o +52XXXXXXXXXX",
    ],*/
    textRulesTelefonoUSA: [
      (v) => !v || /^\(\d{3}\) \d{3}-\d{4}$/.test(v) || "El campo debe contener un número de teléfono válido y seguir el formato +1XXXXXXXXXX.",
    ],
    textRulesLicencia: [
      (v) => {
        // Verifica si el valor es un número y tiene entre 1 y 8 dígitos
        //const isValidLength = v === '' || /^\d{1,8}$/.test(v);
        // Si es válido, verifica la longitud
        /*
        if (isValidLength) {
          // Permite completar con ceros hasta 8 dígitos
          return v.length <= 8 ? true : "El número de licencia puede tener hasta 8 dígitos.";
        }
        return "El número de licencia debe contener solo dígitos y tener entre 1 y 8 dígitos.";
        */
        const isValidFormat = v === '' || /^[0-9]{1,8}?$/.test(v);
        return isValidFormat || "El número de licencia puede tener hasta 8 dígitos.";
      },
    ],
    textRulesCertificacion: [
      (v) => !v || /^[a-zA-Z0-9-]+$/.test(v) || 'El número de certificación puede tener letras, números o guiones',
      (v) => !v || v.toString().length <= 20 || 'El número de certificación no puede exceder los 20 caracteres'
    ],
    textRulesEspecialidad: [
      (v) => !v || /^[a-zA-Z0-9-]+$/.test(v) || 'El número de certificación puede tener letras, números o guiones'
    ],
    textRulesLicenseProfessional: [
      (v) => !v || /^[a-zA-Z0-9-]+$/.test(v) || 'El número de certificación puede tener letras, números o guiones'
    ],
    textRulesFacebookUrl: [
      (v) => {
        // Verifica si la URL comienza con https://www.facebook.com/
        const isValidUrl = v === '' || /^https:\/\/www\.facebook\.com\/.+$/.test(v);
        return isValidUrl || "La URL de Facebook debe comenzar con https://www.facebook.com/ y contener un identificador válido.";
      },
    ],
    textRulesXUrl: [
      (v) => {
        // Verifica si la URL comienza con https://x.com/
        const isValidUrl = v === '' || /^https:\/\/x\.com\/.+$/.test(v);
        return isValidUrl || "La URL de la Red X debe comenzar con https://x.com/ y contener un identificador válido.";
      },
    ],
    textRulesInstagramUrl: [
      (v) => {
        // Verifica si la URL comienza con https://www.instagram.com/
        const isValidUrl = v === '' || /^https:\/\/www\.instagram\.com\/.+$/.test(v);
        return isValidUrl || "La URL de Instagram debe comenzar con https://www.instagram.com/ y contener un identificador válido.";
      },
    ],
    textRulesYoutubeUrl: [
      (v) => {
        // Verifica si la URL comienza con https://www.youtube.com/
        const isValidUrl = v === '' || /^https:\/\/www\.youtube\.com\/.+$/.test(v);
        return isValidUrl || "La URL de Youtube debe comenzar con https://www.youtube.com/ y contener un identificador válido.";
      },
    ],
    textRulesTiktokUrl: [
      (v) => {
        // Verifica si la URL comienza con https://www.youtube.com/
        const isValidUrl = v === '' || !v || /^https:\/\/www\.tiktok\.com\/.+$/.test(v);
        return isValidUrl || "La URL de Youtube debe comenzar con https://www.tiktok.com/ y contener un identificador válido.";
      },
    ],
    // textRulesHorarioAtencion: [
    //   (v) => {
    //     // Verifica si el valor sigue el formato de hora
    //     const isValidTimeFormat = v === '' || /^(0?[1-9]|1[0-2]):[0-5][0-9] ?([APap][mM])?$|^(1[0-9]|2[0-3]):[0-5][0-9]$/.test(v);
    //     return isValidTimeFormat || "El horario de atención debe estar en formato HH:MM AM/PM o HH:MM (24 horas).";
    //   },
    // ],
    textRulesHorarioAtencion: [
      (v) => {
        // Verifica si el valor está vacío
        if (!v || v == '') return true;

        // const timeFormat = /^(\d{2}:\d{2} (am|pm|AM|PM) - \d{2}:\d{2} (am|pm|AM|PM)|\d{2}:\d{2} - \d{2}:\d{2})$/;

        // if (!timeFormat.test(v)) {
        //   return "Ambos horarios deben estar en formato hh:mm am/pm - hh:mm am/pm";
        // }
        // Divide el rango en dos partes
        const times = v.split(' - ');
        // Asegúrate de que haya exactamente dos partes
        if (times.length !== 2) return "Formato hh:mm am/pm - hh:mm am/pm";
        const [startTime, endTime] = times.map(time => time.trim());
        // Expresión regular para validar el formato de hora
        // Verifica si ambos horarios son válidos
        // Función para convertir la hora a minutos desde la medianoche
        const convertToMinutes = (time) => {
          let [hour, minutePart] = time.split(':');
          minutePart = minutePart.toLocaleUpperCase();
          let minutes = parseInt(hour) * 60 + parseInt(minutePart.slice(0, 2));
          // Sumar 12 horas si es PM
          if (minutePart.includes('PM') && hour != '12') {
            minutes += 720;
          }
          // Restar 12 horas si es AM y es mediodía
          if (minutePart.includes('AM') && hour == '12') {
            minutes -= 720;
          }
          return minutes;
        };
        const startMinutes = convertToMinutes(startTime);
        const endMinutes = convertToMinutes(endTime);
        // Verifica que el horario de inicio sea anterior al horario de fin
        return startMinutes < endMinutes || "El horario de inicio debe ser anterior al horario de fin.";
      },
    ],
    textRules: [(v) => !!v || "Este campo es obligatorio!"],
    emailRules: [
      //(v) => !!v || "El Correo Electrónico es obligatorio",
      (v) => v === '' || /.+@.+\..+/.test(v) || "El correo electrónico debe ser válido",
    ],
    imageRules: [
      (value) =>
          !value ||
          value.size < 2000000 ||
          "El tamaño de la imagen no debe de sobrepasar los 2 MB!",
    ],
    rulesLicense: [
      v => !!v || 'Este campo es requerido',
      v => /^[a-zA-Z0-9-]+$/.test(v) || 'El número de licencia puede contener número, letras y guiones'
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      excellence: false,
      premium: false,
      basic: false,
      member: false,
      repsabi: false,
      videos: [],
      name: "",
      register_number: "",
      description: "",
      description_english: "",
      address: "",
      email: "",
      phone1: "",
      phone2: "",
      map_lat: 0.0,
      map_lng: 0.0,
      city_id: 0,
      web_page: "",
      profesional_license_number: "",
      specialty_license_number: "",
      certification_validity: "",
      certificate_number: "",
      professional_license_verification: "",
      facebook_profile: "",
      twitter_profile: "",
      instagram_profile: "",
      youtube_video: "",
      membership: 0,
      servicehours: "",
      translation_id: 0,
      pictures: [],
      associations: [],
      certifications: [],
      specialties: [],
      tiktok: "",
      sku: "",
      outstanding: false,
      coparmex: false,
      state: 'Pendiente',
      whatsapp: "",
      extra_services: "",
      speak_english: "",
      english: "",
      professional_organism: "",
      integral_services: "",
      extra_services_tourism: "",
      tourism_name: "",
      repssabi_folio: "",
      repssabi_expire_date: "",
      conacem_date: "",
      conacem_expire_date: "",
      accept_term: false,
      comment: "",
      register_repssabi: "",
      gender: "",
      rfc: "",
      full_name: "",
      ret: "",
      postal_code: "",
      colonia: "",
      state_country: "",
      city_country: "",
      street: "",
      num_ext: null,
      num_int: null,
      municipality: "",
      comment_ceetsb: "",
      comment_coparmex: "",
      comment_coepris: "",
      state_ceetsb: "Pendiente",
      state_coparmex: "Pendiente",
      state_coepris: "Pendiente",
    },
    defaultItem: {
      id: 0,
      name: "",
      register_number: "",
      description: "",
      videos: [],
      description_english: "",
      excellence: false,
      premium: false,
      repsabi: false,
      member: false,
      basic: false,
      address: "",
      email: "",
      phone1: "",
      phone2: "",
      map_lat: 0.0,
      map_lng: 0.0,
      city_id: 0,
      web_page: "",
      profesional_license_number: "",
      specialty_license_number: "",
      certification_validity: "",
      certificate_number: "",
      professional_license_verification: "",
      facebook_profile: "",
      twitter_profile: "",
      instagram_profile: "",
      youtube_video: "",
      membership: 0,
      servicehours: "",
      translation_id: 0,
      pictures: [],
      associations: [],
      certifications: [],
      specialties: [],
      tiktok: "",
      sku: "",
      outstanding: false,
      coparmex: false,
      state: 'Pendiente',
      whatsapp: "",
      extra_services: "",
      speak_english: "",
      english: "",
      professional_organism: "",
      integral_services: "",
      extra_services_tourism: "",
      tourism_name: "",
      repssabi_folio: "",
      repssabi_expire_date: "",
      conacem_date: "",
      conacem_expire_date: "",
      accept_term: false,
      comment: "",
      register_repssabi: "",
      gender: "",
      rfc: "",
      full_name: "",
      ret: "",
      postal_code: "",
      colonia: "",
      state_country: "",
      city_country: "",
      street: "",
      num_ext: null,
      num_int: null,
      municipality: "",
      comment_ceetsb: "",
      comment_coparmex: "",
      comment_coepris: "",
      state_ceetsb: "Pendiente",
      state_coparmex: "Pendiente",
      state_coepris: "Pendiente",
    },
    saveChanges: false,

    filterPendings: false,

    dialogDeleteQr: false,
    dialogQrCode: false,
    auxItems: [],
  }),
  filters: {
    filterRoles(value){
      switch (value) {
        case "ADMIN":
          return ["COPARMEX", "REPSSABI", "CEETSB"];
        case "COPARMEX":
          return ["COPARMEX", "REPSSABI", "CEETSB"];
        case "COEPRIS":
          return ["REPSSABI"];
        case "CEETSB":
          return ["CEETSB"];
        default:
          return [];
      }
    },
    formatPhone(value) {
      if (value === null || value == "undefined") {
        return "";
      }
      return (
          "(" +
          value.substring(0, 3) +
          ") " +
          value.substring(3, 6) +
          "-" +
          value.substring(6)
      );
    },
  },
  computed: {
    countPending(){
      let f = null;
      switch(this.user.roles){
        case 'ADMIN':
          f = this.doctors.filter(e=>e.state_coparmex == 'Pendiente' 
            || e.state_ceetsb == 'Pendiente' 
            || e.state_coepris == 'Pendiente');
          break;
        case 'COPARMEX':
          f = this.doctors.filter(e=>e.state_coparmex == 'Pendiente' 
            || e.state_ceetsb == 'Pendiente' 
            || e.state_coepris == 'Pendiente');
          break;
        case 'CEETSB':
          f = this.doctors.filter(e=>e.state_ceetsb == 'Pendiente');
          break;
        case 'REPSSABI':
          f = this.doctors.filter(e=>e.state_coepris == 'Pendiente');
          break;
        default:
          break;        
      }
      return f ? f.length : 0;
    },
    deleteUrlServicesCertification() {
      return `${process.env.VUE_APP_BHC_BASE_URL}/delete-certification/doctors`;
    },
    urlServicesCertification() {
      return `${process.env.VUE_APP_BHC_BASE_URL}/add-certification/doctors`;
    },
    urlServices() {
      return `${process.env.VUE_APP_BHC_BASE_URL}/keywords/doctors`;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Médico" : "Editar Médico";
    },
    places() {
      let listPlaces = [];
      if (this.placesList) {
        listPlaces = this.placesList.map((entry) => {
          const PlaceName =
              entry.place_name.length > this.placeNameLimit
                  ? entry.place_name.slice(0, this.placeNameLimit) + "..."
                  : entry.place_name;
          console.log(PlaceName);
          return Object.assign({}, entry);
        });
      }
      return listPlaces;
    },
  },
  watch: {
    filterPendings(val){
      switch(this.user.roles){
        case 'ADMIN':
          this.doctors = val?this.auxItems.filter(e=>e.state_coparmex == 'Pendiente' 
            || e.state_ceetsb == 'Pendiente' 
            || e.state_coepris == 'Pendiente'):[...this.auxItems];
          break;
        case 'COPARMEX':
          this.doctors = val?this.auxItems.filter(e=>e.state_coparmex == 'Pendiente' 
            || e.state_ceetsb == 'Pendiente' 
            || e.state_coepris == 'Pendiente'):[...this.auxItems];
          break;
        case 'CEETSB':
          this.doctors = val?this.auxItems.filter(e=>e.state_ceetsb == 'Pendiente'):[...this.auxItems];
          break;
        case 'REPSSABI':
          this.doctors = val?this.auxItems.filter(e=>e.state_coepris == 'Pendiente'):[...this.auxItems];
          break;
        default:
          break;        
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogDeletePicture(val) {
      val || this.closeDeletePicture();
    },
    searchQuery(val) {
      if (this.queryReady(val)) {
        this.searchPlaces(val);
      }
    },

    dialogDeleteQr(val) {
      val || this.closeDeleteQr();
    },
  },
  methods: {
    updateVideo(videos){
      this.editedItem.videos = videos;
    },
    changeCommentEdit(){
      const {state_coparmex, state_ceetsb, state_coepris} = this.editedItem;
      if(state_coparmex == 'Rechazado'){
        const commentCoparmex = this.editedItem.changes_states.find(e=>e.state=='Rechazado' && e.type_role == 'COPARMEX');
        this.editedItem.comment_coparmex = commentCoparmex?commentCoparmex.comment:'';
      }
      if(state_ceetsb == 'Rechazado'){
        const commentCeetsb = this.editedItem.changes_states.find(e=>e.state=='Rechazado' && e.type_role == 'CEETSB');
        this.editedItem.comment_ceetsb = commentCeetsb?commentCeetsb.comment:'';
      }
      if(state_coepris == 'Rechazado'){
        const commentCoepris = this.editedItem.changes_states.find(e=>e.state=='Rechazado' && e.type_role == 'REPSSABI');
        this.editedItem.comment_coepris = commentCoepris?commentCoepris.comment:'';
      }
    },
    fixStateColor(state) {
      switch (state) {
        case 'Rechazado':
          return "background-color: #CA2B2BFF;";
        case 'Pendiente':
          return "background-color: #6E87DBFF;";
        case 'Aprobado':
          return "background-color: #6bc263;";
        case 'Si':
          return "background-color: #6bc263;";
        case 'No':
          return "background-color: #cf7336;";
        default:
          return "background-color: #000;";
      }
    },
    fixStateLabel(item) {
      switch (this.user.roles) {
        case 'CEETSB':
          return item.state_ceetsb;
        case 'COEPRIS':
          return item.state_coepris;
        case 'COPARMEX':
          return item.state_coparmex;
        case 'USER':
          return item.state_coparmex;
        default:
          return item.state;
      }
    },
    fixStateLabelExtra(state) {
      switch (state !== "") {
        case state !== "":
          if (state === 0 || state === "Pendiente") {
            return "Pendiente";
          } else if (state === 1 || state === "Aprobado") {
            return "Aprobado";
          } else {

            return "Rechazado";
          }
      }
    },
    fixStateLabelYesOrNot(state) {
      switch (state !== "") {
        case state !== "":
          if (state === 0) {
            return "No";
          } else if (state === 1) {
            return "Si";
          } else {
            return "N/A";
          }
      }
    },
    speciality(specialities) {
      let out = "";
      specialities.forEach(e => {
        out = `${out}${e.name}, `;
      })
      return out.substring(0, out.length - 2);
    },
    queryReady(val) {
      return val !== null && val !== undefined && val !== "";
    },
    onClear() {
      this.$emit("input", null);
      this.$emit("clear");
    },
    async searchPlaces(query) {
      // console.log('val:', val);
      if (query === null || query.length === 0) return;
      // Items have already been loaded
      let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?types=address&access_token=${this.mapBoxApiKey}`;
      this.loadingPlaces = true;
      // Lazily load input items
      fetch(url)
          .then((res) => res.json())
          .then((res) => {
            // console.log('res: ', res);
            const {query, features} = res;
            this.query = query;
            this.placesList = features;
            // console.log('places: ', this.places);
          })
          .catch((err) => {
            console.log("Error ejecutando API: ", err);
          })
          .finally(() => (this.loadingPlaces = false));
    },
    getSelectedItem(item) {
      this.selectedPlace = Object.assign({}, item);
    },

    //Validaciones
    vtextRulesNombre(valNumero) {
      this.erroresNombre = [];
      if (valNumero !== null) {
        if (valNumero == "") {
          this.erroresNombre = ['Este campo es obligatorio!'];
          return false;
        }
        const valid = /^[a-zA-Z .ñáéíóúÁÉÍÓÚüÜ]+$/.test(valNumero);
        this.erroresNombre = valid ? [] : ["El campo debe contener solo letras, el espacio y el punto"];
        return valid;
      }
      this.erroresNombre = [];
      return true;
    },
    vtextRulesDireccion(valNumero) {
      this.erroresDireccion = [];
      if (valNumero !== null) {
        if (valNumero == "") {
          this.erroresDireccion = ['Este campo es obligatorio!'];
          return false;
        }
      }
      this.erroresDireccion = [];
      return true;
    },
    vtextRulesTelefonoMexico(valNumero) {
      this.erroresTelefonoMexico = [];
      if (valNumero !== null) {
        if (valNumero == "" || valNumero == "+52") {
          this.erroresTelefonoMexico = ['Este campo es obligatorio!'];
          return false;
        }
        const valid = /^(\+52\d{10})$/.test(valNumero);
        this.erroresTelefonoMexico = valid ? [] : ["El campo debe contener un número de teléfono válido y seguir el formato +52XXXXXXXXXX"];
        return valid;
      }
      this.erroresTelefonoMexico = [];
      return true;
    },
    vtextRulesCiudad(valNumero) {
      this.erroresCiudad = [];
      if (valNumero !== null) {
        if (valNumero == "") {
          this.erroresCiudad = ['Este campo es obligatorio!'];
          return false;
        }
      }
      this.erroresCiudad = [];
      return true;
    },
    vtextRulesLicencia(valNumero) {
      this.erroresLicencia = [];
      if (valNumero !== null) {
        if (valNumero == "") {
          this.erroresLicencia = ['Este campo es obligatorio!'];
          return false;
        }
        const valid = /^[a-zA-Z0-9-]+$/.test(valNumero);
        this.erroresLicencia = valid ? [] : ["El número de licencia puede tener hasta 8 dígitos"];
        return valid;
      }
      this.erroresLicencia = [];
      return true;
    },
    vtextRulesEspecialidad(valNumero) {
      this.erroresEspecialidad = [];
      if (valNumero !== null) {
        if (valNumero == "") {
          this.erroresEspecialidad = ['Este campo es obligatorio!'];
          return false;
        }
      }
      this.erroresEspecialidad = [];
      return true;
    },

    isFechaVencida(fecha) {
      // Obtiene solo la parte de la fecha
      const fechaVencimiento = fecha.split("T")[0];
      // Formatea la fecha actual en el mismo formato
      const fechaActual = new Date().toISOString().split("T")[0];
      //console.log('Fecha Vencimiento:', fechaVencimiento);
      //console.log('Fecha Actual:', fechaActual);
      // Compara las fechas
      return fechaVencimiento > fechaActual;
    },
    isFechaCercanaTreintaDias(fecha) {
      // Convierte la fecha de vencimiento a objeto Date
      const fechaVencimiento = new Date(fecha);
      // Obtiene la fecha actual
      const fechaActual = new Date();
      // Calcula la diferencia en milisegundos
      const diferenciaEnMilisegundos = fechaVencimiento - fechaActual;
      // Convierte la diferencia a días
      const diferenciaEnDias = Math.ceil(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
      // Verifica si faltan 30 días
      return diferenciaEnDias == 30;
    },
    isFechaCercanaQuinceDias(fecha) {
      // Convierte la fecha de vencimiento a objeto Date
      const fechaVencimiento = new Date(fecha);
      // Obtiene la fecha actual
      const fechaActual = new Date();
      // Calcula la diferencia en milisegundos
      const diferenciaEnMilisegundos = fechaVencimiento - fechaActual;
      // Convierte la diferencia a días
      const diferenciaEnDias = Math.ceil(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
      // Verifica si faltan 15 días
      return diferenciaEnDias == 15;
    },
    enviarNotificacionCorreo(doctor_id, send_emails) {
      doctorsService.sendManyEmail(doctor_id, send_emails).then((data) => {
        if (data.success) {
          console.log("Correo enviado: ", data);
        } else {
          console.log("Error enviando correo: ", data);
        }
      });
    },
    getDoctors() {
      this.loadingData = true;
      // Nomenclador de Ciudades
      citiesService.getAllRecords().then((record) => {
        this.cities = record.value;
        // console.log("Ciudades: ", this.cities);
      });
      // Nomenclador de Certificaciones
      certificationsService.getViewList().then((record) => {
        this.certificationsList = record.value;
        // console.log("Certificaciones: ", this.certificationsList);
      });
      // Nomenclador de Especialidades
      specialtiesService.getViewList().then((record) => {
        this.specialtiesList = record.value;
        // console.log("Especialidades: ", this.specialtiesList);
      });
      // Catalogo de Medicos...
      doctorsService.getViewList().then((record) => {
        this.doctors = record.value.map(e => {
          return {
            ...e,
            email: e.email && e.email != null && e.email != "null" ? e.email : "",
            web_page: e.web_page && e.web_page != null && e.web_page != "null" ? e.web_page : "",
            facebook_profile: e.facebook_profile && e.facebook_profile != null && e.facebook_profile != "null" ? e.facebook_profile : "",
            instagram_profile: e.instagram_profile && e.instagram_profile != null && e.instagram_profile != "null" ? e.instagram_profile : "",
            youtube_video: e.youtube_video && e.youtube_video != null && e.youtube_video != "null" ? e.youtube_video : "",
            twitter_profile: e.twitter_profile && e.twitter_profile != null && e.twitter_profile != "null" ? e.twitter_profile : "",
          }
        });
        this.auxItems = record.value.map(e => {
          return {
            ...e,
            email: e.email && e.email != null && e.email != "null" ? e.email : "",
            web_page: e.web_page && e.web_page != null && e.web_page != "null" ? e.web_page : "",
            facebook_profile: e.facebook_profile && e.facebook_profile != null && e.facebook_profile != "null" ? e.facebook_profile : "",
            instagram_profile: e.instagram_profile && e.instagram_profile != null && e.instagram_profile != "null" ? e.instagram_profile : "",
            youtube_video: e.youtube_video && e.youtube_video != null && e.youtube_video != "null" ? e.youtube_video : "",
            twitter_profile: e.twitter_profile && e.twitter_profile != null && e.twitter_profile != "null" ? e.twitter_profile : "",
          }
        });
      }).catch(() => {
        this.snackText = "Ha ocurrido un error al cargar los datos";
        this.snackBar = true;
      }).finally(() => {
        this.loadingData = false;
      });
    },
    newItem() {
      this.valid = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    editItem(item) {
      console.log(item);
      this.editedIndex = this.doctors.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.editedItem.vencimiento_fecha = this.formatDate(
          this.editedItem.vencimiento_fecha
      );
      this.changeCommentEdit();
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.doctors.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.editedItem.vencimiento_fecha = this.formatDate(
          this.editedItem.vencimiento_fecha
      );
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.saveChanges = true;
      doctorsService.deleteRecord(this.editedItem.id).then((response) => {
        if (response.success) {
          this.doctors.splice(this.editedIndex, 1);
          this.snackText = "Registro eliminado con éxito.";
          this.getDoctors();
          this.closeDelete();
        } else {
          this.snackText = "Un error impidió eliminar el registro!";
        }
      }).catch((err) => {
        console.log(err);
        this.snackText = "Un error impidió eliminar el registro!";
      }).finally(() => {
        this.snackBar = true;
        this.saveChanges = false;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.saveChanges = true;
        if (this.selectedPlace.center && this.selectedPlace.center.length > 0) {
          this.editedItem.address = this.selectedPlace.place_name;
          this.editedItem.map_lng = this.selectedPlace.center[0];
          this.editedItem.map_lat = this.selectedPlace.center[1];
        }
        if (this.editedIndex > -1) {
          Object.assign(this.doctors[this.editedIndex], this.editedItem);
          doctorsService.updateRecord(this.editedItem).then((response) => {
            if (response.success) {
              this.getDoctors();
              this.snackText = "Registro guardado con éxito.";
              this.close();
            } else {
              this.snackText = "Ha ocurrido un error";
            }
          }).catch((err) => {
            console.log(err);
            this.snackText = "Ha ocurrido un error";
          }).finally(() => {
            this.snackBar = true;
            this.saveChanges = false;
          });
        } else {
          doctorsService.addRecord(this.editedItem).then((response) => {
            if (response.success) {
              this.doctors.push(this.editedItem);
              this.getDoctors();
              this.snackText = "Registro guardado con éxito.";
              this.close();
            }
            if (response == null) {
              this.snackText = "ERROR al registrar los datos.";
            }
          }).catch((err) => {
            console.log(err);
            this.snackText = "ERROR al registrar los datos.";
          }).finally(() => {
            this.snackBar = true;
            this.saveChanges = false;
          });
        }
      }
    },
    /* Para administrar las imagenes */
    loadGalleryImages(item) {
      this.selectedFiles = [];
      this.imageFiles = [];
      // Carga las imagenes...
      doctorsService.getRecord(item.id).then((fetch_data) => {
        this.editedItem = Object.assign({}, fetch_data.value);

        this.editedItem.vencimiento_fecha = this.formatDate(
            this.editedItem.vencimiento_fecha
        );
      });
      if (!this.dialogGalleryPhotos) this.dialogGalleryPhotos = true;
    },

    // qr

    loadQrCode(item) {
      this.selectedFiles = [];
      this.imageFiles = [];
      doctorsService.getRecord(item.id).then((fetch_data) => {
        this.editedItem = Object.assign({}, fetch_data.value);
        this.editedItem.vencimiento_fecha = this.formatDate(
            this.editedItem.vencimiento_fecha
        );
      });
      if (!this.dialogQrCode) this.dialogQrCode = true;
    },

    deleteQr(item) {
      this.currentPicture = item;
      this.dialogDeleteQr = true;
    },

    closeDeleteQr() {
      this.dialogDeleteQr = false;
    },

    deleteItemConfirmQr() {
      this.saveChanges = true;
      doctorsService
          .deleteQr(this.editedItem.id, this.currentPicture)
          .then((result) => {
            this.closeDeleteQr();
            if (result.success) {
              this.snackText = "Qr eliminado con éxito!";
              this.loadQrCode(this.editedItem);
            } else {
              this.snackText = "Un error impidió eliminar el Qr!";
            }
          }).catch(err => {
        console.log(err);
        this.snackText = "Un error impidió eliminar el Qr!";
      }).finally(() => {
        this.snackBar = true;
        this.saveChanges = false;
      });
    },

    uploadQr() {
      for (let i = 0; i < this.imageFiles.length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
        };
        reader.readAsDataURL(this.imageFiles[i]);
        this.saveChanges = true;
        doctorsService
            .uploadQr(this.editedItem.id, -1, this.imageFiles[i])
            .then((result) => {
              if (result.success) {
                this.loadQrCode(this.editedItem);
                this.snackText = "Qr subido al servidor!";
              } else {
                this.snackText = "Un error impidió subir el qr al servidor!";
              }
            }).catch(err => {
          console.log(err);
          this.snackText = "Un error impidió subir el qr al servidor!";
        }).finally(() => {
          this.snackBar = true;
          this.saveChanges = false;
        });
      }
    },

    /////////////////////////////////////

    // vencimiento qr

    async updateVencimientoFecha(doctor) {
      try {
        this.saveChanges = true;
        doctorsService.updateRecord(doctor).then((response) => {
          if (response) {
            this.getDoctors();
            this.dialogQrCode = false;
            this.snackText = "Fecha de vencimiento de coepris actualizado";
            return response;
          } else {
            this.snackText = "Ha ocurrido un error";
            return null;
          }
        }).catch(err => {
          console.log(err);
          this.snackText = "Ha ocurrido un error";
        }).finally(() => {
          this.snackBar = true;
          this.saveChanges = false;
        });
      } catch (error) {
        console.log(error);
      }
    },

    formatDate(itemDate) {
      if (itemDate) {
        const date = new Date(itemDate);
        return date.toISOString().split("T")[0];
      }
      return null;
    },

    //////////////////////////////////////////////////

    removeChip(index) {
      this.imageFiles.splice(index, 1);
      this.selectedFiles = [...this.imageFiles];
    },
    inputChanged() {
      this.imageFiles = [...this.selectedFiles];
    },
    uploadPictures() {
      for (let i = 0; i < this.imageFiles.length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
        };
        reader.readAsDataURL(this.imageFiles[i]);
        this.saveChanges = true;
        doctorsService
            .uploadImage(this.editedItem.id, -1, this.imageFiles[i])
            .then((result) => {
              if (result.success) {
                this.loadGalleryImages(this.editedItem);
                this.snackText = "Imágen subida al servidor. Galería actualizada!";
              } else {
                this.snackText = "Un error impidió subir al menos una imágen al servidor!";
              }
            }).catch((err) => {
          console.log(err);
          this.snackText = "Un error impidió subir al menos una imágen al servidor!";
        }).finally(() => {
          this.snackBar = true;
          this.saveChanges = false;
        });
      }
    },
    deletePicture(item) {
      this.currentPicture = Object.assign({}, item);
      this.dialogDeletePicture = true;
    },
    deleteItemConfirmPicture() {
      this.saveChanges = true;
      doctorsService
          .deleteImage(
              this.currentPicture.pivot.doctor_id,
              this.currentPicture.id
          )
          .then((result) => {
            this.closeDeletePicture();
            if (result.success) {
              this.snackText = "Imagen eliminada desde la galería!";
              this.loadGalleryImages(this.editedItem);
            } else {
              this.snackText = "Un error impidió eliminar la imágen desde la galería!";
            }
          }).catch((err) => {
        console.log(err);
        this.snackText = "Un error impidió eliminar la imágen desde la galería!";
      }).finally(() => {
        this.snackBar = true;
        this.saveChanges = false;
      });
    },
    closeDeletePicture() {
      this.dialogDeletePicture = false;
    },
    changePreviewImage() {
      if (this.imageFile) {
        let reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
        };
        reader.readAsDataURL(this.imageFile);
        if (this.editedItem.pictures.length === 0) {
          let imagePicture = {
            id: 2,
            module_id: 2,
            url: this.imageFile.name,
          };
          this.editedItem.pictures.push(imagePicture);
        }
      }
    },
    closeDialogError() {
      this.dialogError = false;
      this.messageError = "";
    },
  },
  mounted() {
    this.user = this.$store.getters.getUser;
    this.doctors = [];
    this.getDoctors();
    changeInformationSEO();
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}

.custom-state-div {
  padding: 10px;
  color: #fff;
  border-radius: 20px;
  font-weight: 600;
  text-align: center;
  width: max-content;
  justify-self: center;
}

.picture-list {
  border-radius: 10px;
  max-width: 250px;
}
</style>
